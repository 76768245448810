export default [
  'fetch_tutors@list',
  'fetch_tutor@single',
  'create_tutor@created',
  'remove_tutor',
  'update_course_approval',
  'add_courses',
  'enable_tutor_private_locations@single',
  'disable_tutor_private_locations@single',
];
