import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Router } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { isEmpty, get } from 'lodash';

import { ErrorBar } from 'components/error';
import { asyncComponent } from 'components/app';

import Path from 'utils/path';
import Storage from 'utils/storage';
import actions from 'modules/actions';

const routes = {
  base: {
    path: Path.root.get(),
    Component: asyncComponent(() => import('./Setup')),
  },
  login: {
    path: Path.login.get(),
    Component: asyncComponent(() => import('./Login')),
  },
  home: {
    path: Path.home.get(),
    Component: asyncComponent(() => import('./Home')),
  },
  other: {
    Component: asyncComponent(() => import('./PageNotFound')),
  },
};

const AppBase = props => {
  const { initialized, authenticated, errorMessage, onClearError, token, history } = props;
  React.useEffect(() => {
    if (token) {
      Storage.setToken(token);
    }
  }, [token]);
  return (
    <React.Fragment>
      <Router history={history}>
        <Switch>
          <Route exact path={routes.base.path} render={() => {
            if (initialized) {
              const redirect = authenticated ? routes.home.path : routes.login.path;
              return <Redirect to={redirect} />;
            }
            return (
              <routes.base.Component />
            );
          }} />
          <Route exact path={routes.login.path} render={() => {
            if (initialized) {
              if (authenticated) {
                return <Redirect to={routes.home.path} />;
              }
              return (
                <routes.login.Component />
              );
            }
            return <Redirect to={routes.base.path} />;
          }} />
          <Route path={routes.home.path} render={() => {
            if (authenticated) {
              return (
                <routes.home.Component />
              );
            }
            return <Redirect to={routes.base.path} />;
          }} />
          <Route component={routes.other.Component} />
        </Switch>
      </Router>
      <ErrorBar
        message={errorMessage}
        onPressClose={onClearError}
      />
    </React.Fragment>
  );
};

AppBase.propTypes = {
  initialized: PropTypes.bool.isRequired,
  authenticated: PropTypes.bool.isRequired,
  onClearError: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  const { request: { profile: { authentication, account } } } = state;

  let authenticated = false;
  if (!isEmpty(authentication.data) && !isEmpty(account.data)) {
    const token = get(authentication, 'data.adminToken');
    const admin = get(account, 'data');
    const user = get(account, 'data.user');
    if (!isEmpty(user) && !isEmpty(admin) && user._id && admin._id && token) {
      authenticated = true;
    }
  }

  return {
    errorMessage: get(state, 'local.error'),
    initialized: get(state, 'local.initialized'),
    authenticated,
    token: get(state, 'request.profile.authentication.data.adminToken') || '',
    history: state.history,
  };
};

const mapDispatchToProps = dispatch => ({
  onClearError: () => dispatch(actions.local.onClearError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppBase);
