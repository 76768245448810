import { camelCase, isEmpty } from 'lodash';
import allTypes from './collect_types';

const actions = {};

Object.keys(allTypes).forEach(key => {
  const group = {};
  allTypes[key].forEach(action => {
    if (/@/.test(action)) {
      const [, reducerNames] = action.split('@');
      const requestReducers = reducerNames.split(',');
      requestReducers.forEach(reducer => {
        const actionName = camelCase(`${reducer}Reset`);
        if (!group[actionName]) {
          group[actionName] = payload => ({
            type: `${key}_${reducer}_reset`,
            payload,
          });
        }
      });
    } else {
      group[camelCase(action)] = payload => ({ type: action, payload });
    }
  });
  if (!isEmpty(group)) {
    actions[key] = group;
  }
});

export default actions;
