import { upperFirst } from 'lodash';

import allTypes from './collect_types';

const types = {};

Object.keys(allTypes).forEach(t => {
  const typeName = `${upperFirst(t)}Types`;
  const fixedTypes = {};
  allTypes[t].forEach(action => {
    const noAnnotations = action.replace(/@(.*?)*/g, '');
    fixedTypes[noAnnotations.toUpperCase()] = noAnnotations;
  });
  types[typeName] = fixedTypes;
});

export default types;
