/* components/error/ErrorBar/styles.js */

import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  content: {
    backgroundColor: theme.palette.secondary.dark,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));
