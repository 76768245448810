/* components/app/async_component.js */

import React from 'react';

const asyncComponent = importComponent => {
  const AsyncComponent = props => {
    const [component, setComponent] = React.useState(null);
    React.useEffect(() => {
      const loadComponent = async () => {
        const { default: importedComponent } = await importComponent();
        setComponent(importedComponent);
      };
      loadComponent();
    }, []);

    const Component = component;
    if (!Component) {
      return null;
    }

    return <Component {...props} />;
  };

  return AsyncComponent;
};

export default asyncComponent;
