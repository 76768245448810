import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from './create_root_reducer';
import requestMiddleware from './request_middleware';

let devtools = x => x;

if (
  process.env.NODE_ENV !== 'production'
  && process.browser
  && window.__REDUX_DEVTOOLS_EXTENSION__
) {
  devtools = window.__REDUX_DEVTOOLS_EXTENSION__();
}

const create = (initialState, history) => {
  let middleware = [requestMiddleware(), routerMiddleware(history)];

  if (
    process.env.NODE_ENV !== 'production'
    && process.browser
    && !window.__REDUX_DEVTOOLS_EXTENSION__
    && Object.assign
  ) {
    const createLogger = require('redux-logger').createLogger;
    middleware = [...middleware, createLogger()];
  }

  return createStore(
    createRootReducer(history),
    initialState,
    compose(
      applyMiddleware(...middleware),
      devtools
    )
  );
};

export default (initialState, history) => {
  if (!process.browser) {
    return create(initialState, history);
  }

  if (!global.__INIT_REDUX_STORE__) {
    global.__INIT_REDUX_STORE__ = create(initialState, history);
  }

  return global.__INIT_REDUX_STORE__;
};
