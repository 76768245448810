const moduleNames = [
  'courses',
  'dashboard',
  'lessons',
  'local',
  'locations',
  'profile',
  'services',
  'students',
  'tutors',
  'users',
];

const collect = {};

moduleNames.forEach(name => {
  collect[name] = require(`modules/${name}/action_types`).default;
});

export default collect;
