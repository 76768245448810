import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  button: {
    width: '100%',
  },
  header: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  open: {
    transform: 'rotate(180deg)',
  },
}));
