import React from 'react';
import PropTypes from 'prop-types';

import { Box, LinearProgress, Typography } from '@material-ui/core';
import SectionPaper from 'components/app/SectionPaper';

const NotAvailable = ({ title, text, loading }) => (
  <SectionPaper title={title}>
    <Box p={2}>
      {loading ? (
        <LinearProgress variant="query" />
      ) : (
        <Typography variant="caption" color="textSecondary">
          {text}
        </Typography>
      )}
    </Box>
  </SectionPaper>
);

NotAvailable.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

NotAvailable.defaultProps = {
  loading: false,
};

export default NotAvailable;
