import types from 'modules/action_types';
const { LocalTypes } = types;

const initialState = {
  error: '',
  initialized: false,
  locale: 'en',
  title: 'dashboard',
  selectedUniversity: '',
  selectedOrganization: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LocalTypes.ON_ERROR:
      return { ...state, error: payload };
    case LocalTypes.ON_CLEAR_ERROR:
      return { ...state, error: '' };
    case LocalTypes.ON_CHANGE_LOCALE:
      return { ...state, locale: payload };
    case LocalTypes.ON_CHANGE_INITIALIZE:
      return { ...state, initialized: payload };
    case LocalTypes.ON_CHANGE_TITLE:
      return { ...state, title: payload };
    case LocalTypes.SELECT_UNIVERSITY:
      return { ...state, selectedUniversity: payload };
    case LocalTypes.SELECT_ORGANIZATION:
      return { ...state, selectedOrganization: payload };
    default:
      return state;
  }
};
