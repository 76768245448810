import { isEmpty } from 'lodash';
import { chain } from 'utils/misc';
import allTypes from './collect_types';

const initialState = {
  loading: false,
  data: {},
  error: null,
  lastUpdated: null,
};

export const transform = types => {
  const collectedTransformed = {};
  Object.keys(types).forEach(key => {
    const transformed = {};
    types[key].forEach(action => {
      if (/@/.test(action)) {
        const [actionName, joinedRequestNames] = action.split('@');
        const requestNames = joinedRequestNames.split(',');
        transformed[actionName] = requestNames;
      }
    });
    if (!isEmpty(transformed)) {
      collectedTransformed[key] = transformed;
    }
  });
  return collectedTransformed;
};

export const regroup = transformed => {
  const collectedRegrouped = {};
  Object.keys(transformed).forEach(key => {
    const regrouped = {};
    const currentAction = transformed[key];
    Object.keys(currentAction).forEach(action => {
      currentAction[action].forEach(reducerName => {
        if (!regrouped[reducerName]) {
          regrouped[reducerName] = [];
        }
        regrouped[reducerName].push(action);
      });
    });
    collectedRegrouped[key] = regrouped;
  });
  return collectedRegrouped;
};

const createReducer = regrouped => {
  const collectedReducer = {};
  Object.keys(regrouped).forEach(key => {
    const reducer = {};
    const currentReducer = regrouped[key];
    Object.keys(currentReducer).forEach(branch => {
      const reducers = action => currentReducer[branch].map((actionName, i) => {
        return state => {
          switch (action.type) {
            case actionName:
              return { ...state, loading: true, error: null };
            case `${actionName}_success`:
              return {
                ...state,
                loading: false,
                data: action.payload[branch],
                lastUpdated: new Date().getTime(),
              };
            case `${actionName}_fail`:
              return { ...state, loading: false, error: action.payload };
            default:
              return state;
          }
        };
      });
      reducer[branch] = (state = initialState, action) => {
        if (action.type === `${key}_${branch}_reset`) {
          return initialState;
        }
        return chain(...reducers(action))(state);
      };
    });
    collectedReducer[key] = reducer;
  });
  return collectedReducer;
};

export default chain(transform, regroup, createReducer)(allTypes);
