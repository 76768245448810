class Errors {
  server(requestPayload) {
    console.error('server error', requestPayload);
    return this.throw({
      type: 'server_error',
      payload: requestPayload,
    });
  }

  request(errors) {
    console.error('request error', errors);
    return this.throw({
      type: 'operation_failed',
      payload: errors[0]
    });
  }

  export(message) {
    console.error('export error', message);
    return this.throw({
      type: 'export',
      payload: message,
    });
  }

  requestPayload(error) {
    console.error('request payload error', error);
    return this.throw({
      type: error.type,
      payload: error.payload,
    });
  }

  throw(obj) {
    if (!obj.type) {
      console.error(obj);
      return new Error(JSON.stringify({ type: 'unknown', payload: obj }));
    }
    return new Error(JSON.stringify(obj));
  }

  parse(message) {
    let parsed;
    try {
      parsed = JSON.parse(message);
      const { type, payload } = parsed;
      return { type, payload };
    } catch {
    } finally {
      if (!parsed) {
        console.error(message);
        return { type: 'unknown', payload: {} };
      }
    }
  }
}

export default new Errors();
