export const chain = (...funcs) => {
  return funcs.reduce((a, b) => (...args) => b(a(...args)), arg => arg);
};

const deg2rad = deg => deg * Math.PI / 180;

export const getDistanceKM = ({ c1, c2 }) => {
  const R = 6371; // Radius of the earth in km
  const { latitude: lat1, longitude: lon1 } = c1;
  const { latitude: lat2, longitude: lon2 } = c2;
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
    + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2))
    * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
};
