/* components/error/ErrorBar/index.js */

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
  IconButton,
  Snackbar,
  SnackbarContent,
} from '@material-ui/core';
import {
  Close as CloseIcon,
  Error as ErrorIcon,
} from '@material-ui/icons';

import useStyles from './styles';

const ErrorBar = ({ message, onPressClose }) => {
  const classes = useStyles();
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={message != null && message !== ''}
      onClose={onPressClose}
    >
      <SnackbarContent
        className={classes.content}
        aria-describedby="error-bar"
        message={
          <span className={classes.message}>
            <ErrorIcon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close-error-bar"
            color="inherit"
            onClick={onPressClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

ErrorBar.propTypes = {
  message: PropTypes.string,
  onPressClose: PropTypes.func.isRequired,
};

ErrorBar.defaultProps = {
  message: '',
};

export default ErrorBar;
