import localforage from 'localforage';
import { set, upperFirst } from 'lodash';
import { encrypt, decrypt } from 'utils/crypt';

import types from './types';

class Storage {
  constructor() {
    Object.keys(types).forEach(key => {
      const name = upperFirst(key);
      set(this, `set${name}`, payload => this.set(key, payload));
      set(this, `get${name}`, () => this.get(key));
      set(this, `remove${name}`, () => this.remove(key));
    });
  }

  set(key, payload) {
    return localforage.setItem(key, {
      data: encrypt(payload),
      date: new Date().getTime(),
    });
  }

  async get(key) {
    const result = await localforage.getItem(key);
    if (!result) {
      return null;
    }

    const { data, date } = result;
    console.warn('TODO: do something with storage date and ttl', date);
    return decrypt(data);
  }

  remove(key) {
    return localforage.removeItem(key);
  }
}

export default new Storage();
