import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Box, ButtonBase, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  button: {
    width: 'calc(100% + 6px)',
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: 4,
    paddingLeft: 4,
    paddingRight: 3,
    left: -4,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    '&:hover': {
      backgroundColor: 'rgb(237, 237, 237)',
    },
  },
}));

const InfoRow = ({ type, value, onClick }) => {
  const classes = useStyles();

  const inner = (
    <>
      <Typography color="textSecondary">
        {`${type}:`}
      </Typography>
      <Typography component="div">
        <Box pl={2} textAlign="right">
          {value}
        </Box>
      </Typography>
    </>
  );

  if (onClick) {
    return (
      <ButtonBase onClick={onClick} className={classes.button}>
        {inner}
      </ButtonBase>
    );
  }

  return (
    <Box width={1} display="flex" flexDirection="row" justifyContent="space-between" my={0.5}>
      {inner}
    </Box>
  );
};

InfoRow.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onClick: PropTypes.func,
};

export default InfoRow;
