/* components/app/SectionPaper/index.js */

import React from 'react';
import PropTypes from 'prop-types';
import { upperFirst } from 'lodash';

import { Divider, Paper, Typography } from '@material-ui/core';
import { Subsection } from 'components/app';
import useStyles from './styles';

const SectionPaper = ({ title, subtitle, children, headerComponents }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Subsection className={classes.header}>
        <div className={classes.textContainer}>
          <Typography className={classes.title}>
            {upperFirst(title)}
          </Typography>
          {subtitle != null && (
            <Typography variant="caption" color="textSecondary">
              {subtitle}
            </Typography>
          )}
        </div>
        <div className={classes.headerComponentsContainer}>
          {headerComponents}
        </div>
      </Subsection>
      <Divider />
      {children}
    </Paper>
  );
};

SectionPaper.propTypes = {
  children: PropTypes.node,
  headerComponents: PropTypes.arrayOf(PropTypes.node),
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

SectionPaper.defaultProps = {
  children: null,
  headerComponents: [],
  subtitle: null,
};

export default SectionPaper;
