import { set, trim } from 'lodash';

const paths = {
  root: { base: '' },
  login: { base: '*' },
  home: {
    base: '*',
    children: {
      services: {
        base: '*',
        children: {
          single: { base: ':serviceId' },
        },
      },
      profile: { base: '*' },
      dashboard: { base: '*' },
      users: {
        base: '*',
        children: {
          single: {
            base: ':userId',
            children: {
              reviews: { base: '*' },
              payment_errors: { base: '*' },
            },
          },
        },
      },
      tutors: {
        base: '*',
        children: {
          create: { base: '*' },
          single: {
            base: ':tutorId',
            children: {
              courses: { base: '*' },
              lessons: { base: '*' },
              calendar: { base: '*' },
            },
          },
        },
      },
      students: {
        base: '*',
        children: {
          single: {
            base: ':studentId',
            children: {
              courses: { base: '*' },
              lessons: { base: '*' },
              credit_transactions: { base: '*' },
            },
          },
        },
      },
      courses: {
        base: '*',
        children: {
          single: {
            base: ':courseId',
            children: {
              update: { base: '*' },
            },
          },
          create: { base: '*' },
        },
      },
      lessons: {
        base: '*',
        children: {
          single: { base: ':lessonId' },
        },
      },
      locations: {
        base: '*',
        children: {
          single: {
            base: ':locationId',
            children: {
              update: { base: '*' },
            },
          },
          create: { base: '*' },
        },
      },
    },
  },
};

class Path {
  constructor() {
    const init = (routes, parentRoute, parentObjectPath) => {
      Object.keys(routes).forEach(key => {
        const obj = routes[key];
        let route = parentRoute;
        if (/\*/.test(obj.base)) {
          route = `${route}/${key}`;
        } else {
          route = `${route}/${obj.base}`;
        }
        const path = parentObjectPath ? `${parentObjectPath}.${key}` : key;
        set(this, `${path}.get`, (context = {}) => {
          let finalPath = route;
          (route.match(/:[\w]+/g) || []).forEach(matched => {
            const matchedWord = trim(matched, ':');
            if (typeof context === 'object' && context[matchedWord]) {
              finalPath = finalPath.replace(matched, context[matchedWord]);
            }
          });
          return finalPath;
        });
        init(obj.children || [], route, path);
      });
    };
    init(paths, '', '');
  }
}

export default new Path();
