/* components/app/Subsection.js */

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}));

const Subsection = ({ children, className }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)}>
      {children}
    </div>
  );
};

Subsection.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Subsection.defaultProps = {
  children: null,
  className: '',
};

export default Subsection;
