import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  logo: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    animation: '$pulse 2s infinite',
  },
  '@keyframes pulse': {
    '0%': {
      'transform': 'scale(1)',
    },
    '50%': {
      'transform': 'scale(1.2)',
    },
    '100%': {
      'transform': 'scale(1)',
    },
  },
}));
