export default [
  'fetch_dashboard_lessons@lessons',
  'fetch_dashboard_users@users',
  'fetch_dashboard_students@students',
  'fetch_popular_courses@courses',
  'change_number_of_lessons_time_frame',
  'change_number_of_lessons_type',
  'change_number_of_lessons_start_date',
  'change_number_of_lessons_end_date',
];
