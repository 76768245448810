/* components/app/Expand/index.js */

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { ButtonBase, Collapse } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import useStyles from './styles';

const Expand = props => {
  const { open, onToggle, children, headerComponent } = props;
  const classes = useStyles();

  const icon = <ExpandMoreIcon className={clsx(classes.expand, open && classes.open)} />;

  return (
    <React.Fragment>
      <ButtonBase className={classes.button} onClick={onToggle}>
        {headerComponent(icon, classes.header)}
      </ButtonBase>
      <Collapse in={open} unmountOnExit>
        {children}
      </Collapse>
    </React.Fragment>
  );
};

Expand.propTypes = {
  headerComponent: PropTypes.func,
  children: PropTypes.node,
  defaultExpanded: PropTypes.bool,
};

Expand.defaultProps = {
  children: null,
  defaultExpanded: false,
};

export default Expand;
