/* components/app/FadeWrapper/index.js */

import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';

const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 0, display: 'none' },
  entered: { opacity: 1, display: 'block' },
  exited: { opacity: 0, display: 'none' },
};

const FadeWrapper = ({ children }) => {
  const [shouldRender, setShouldRender] = React.useState(false);
  React.useEffect(() => {
    setTimeout(() => {
      setShouldRender(true);
    }, 200);
    return () => {
      setShouldRender(false);
    };
  }, []);
  return (
    <Transition in={shouldRender} timeout={duration}>
      {state => (
        <div style={{ ...defaultStyle, ...transitionStyles[state] }}>
          {children}
        </div>
      )}
    </Transition>
  );
};

FadeWrapper.propTypes = {
  children: PropTypes.any.isRequired,
};

export default FadeWrapper;
