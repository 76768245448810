import types from 'modules/action_types';
const { DashboardTypes } = types;

const initialState = {
  numberOfLessonsTimeFrame: 'day',
  numberOfLessonsType: 'requested',
  numberOfLessonsStartDate: '',
  numberOfLessonsEndDate: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case DashboardTypes.CHANGE_NUMBER_OF_LESSONS_TIME_FRAME:
      return { ...state, numberOfLessonsTimeFrame: payload };
    case DashboardTypes.CHANGE_NUMBER_OF_LESSONS_TYPE:
      return { ...state, numberOfLessonsType: payload };
    case DashboardTypes.CHANGE_NUMBER_OF_LESSONS_START_DATE:
      return { ...state, numberOfLessonsStartDate: payload };
    case DashboardTypes.CHANGE_NUMBER_OF_LESSONS_END_DATE:
      return { ...state, numberOfLessonsEndDate: payload };
    default:
      return state;
  }
};
