import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  paper: {
    overflow: 'auto',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  headerComponentsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));
