/* components/app/LoadingScreen/index.js */

import React from 'react';

import { NimbusLogo } from 'images';
import useStyles from './styles';

const LoadingScreen = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <img src={NimbusLogo} className={classes.logo} alt="Nimbus Logo" />
    </div>
  );
};

export default LoadingScreen;
