import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  paper: {
    overflow: 'auto',
  },
}));

const ScrollablePaper = props => {
  const { children } = props;
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      {children}
    </Paper>
  );
};

ScrollablePaper.propTypes = {
  children: PropTypes.any,
};

export default ScrollablePaper;
