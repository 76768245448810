/* components/app/ConfirmationDialog/index.js */

import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';

const ConfirmationDialog = props => {
  const {
    title,
    text,
    warningText,
    open,
    onClose,
    onPressPrimary,
    onPressSecondary,
    secondaryButtonText,
    primaryButtonText,
  } = props;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confimation-dialog-title"
      aria-describedby="confimation-dialog-description"
    >
      <DialogTitle id="confirmation-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="confirmation-dialog-description">
          {text}
        </DialogContentText>
        {warningText && (
          <Box mb={2}>
            <Typography variant="caption" color="secondary">
              {warningText}
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onPressSecondary || onClose}>
          {secondaryButtonText}
        </Button>
        <Button onClick={onPressPrimary} color="primary" autoFocus>
          {primaryButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  warningText: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onPressPrimary: PropTypes.func.isRequired,
  onPressSecondary: PropTypes.func,
  secondaryButtonText: PropTypes.string.isRequired,
  primaryButtonText: PropTypes.string.isRequired,
};

ConfirmationDialog.defaultProps = {
  onPressSecondary: null,
  warningText: '',
};

export default ConfirmationDialog;
