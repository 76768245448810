export default {
  network: {
    development: {
      tp: 'http://',
      tp_ws: 'ws://',
      // domain: '192.168.2.14:8080',
      domain: 'localhost:8888',
    },
    staging: {
      tp: 'https://',
      tp_ws: 'wss://',
      domain: 'nimbus2-stage.herokuapp.com',
    },
    production: {
      tp: 'https://',
      tp_ws: 'wss://',
      domain: 'nimbus2.herokuapp.com',
    },
    endpoints: {
      contract: '/contract/view',
      nimbus: '/nimbus',
      export: '/export',
      image: '/images',
      stripe: '/stripe',
      subscription: '/nimbus_ws',
    },
  },
  mapbox: {
    key: 'pk.eyJ1IjoibmltYnVzLWRldiIsImEiOiJjamp5bHFjaTcxZTFmM3ByejZ1bHZ3YjgxIn0.Ere0Dg-Y6hBTTDBQ8InqhQ',
    style: 'mapbox://styles/nimbus-dev/cjjyo6qf8494r2rqrots0hdwq',
  },
  locales: ['en', 'fr'],
  stripe: {
    currencies: ['cad', 'sgd'],
    development: {
      sgd: {
        client_id: 'ca_DC84YN26pisTXdK3a0pIVpgUmi2vCLQv',
        pk: 'pk_test_XdsaOgwGGCpdYhXNtlU9WH3g',
      },
      cad: {
        client_id: 'ca_DCAcOFyIV6xI89ZptbCza3FU994Uj9E6',
        pk: 'pk_test_6fdOCLpxhVKBWXx7cack3rBa',
      },
    },
    staging: {
      sgd: {
        client_id: 'ca_DC84zMykD9anr9smv0gs1xjbQ14QQLAc',
        pk: 'pk_live_H9sofPuLpFyFoes8miQ4RViX',
      },
      cad: {
        client_id: 'ca_DCAcAceD4OmlDy5OECAke8R0Fc1U8HRV',
        pk: 'pk_live_4zCCla7DaJYO34FXjGEInCZ6',
      },
    },
    production: {
      sgd: {
        client_id: 'ca_DC84zMykD9anr9smv0gs1xjbQ14QQLAc',
        pk: 'pk_live_H9sofPuLpFyFoes8miQ4RViX',
      },
      cad: {
        client_id: 'ca_DCAcAceD4OmlDy5OECAke8R0Fc1U8HRV',
        pk: 'pk_live_4zCCla7DaJYO34FXjGEInCZ6',
      },
    },
  },
};
