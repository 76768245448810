/* components/app/Row/index.js */

import React from 'react';
import PropTypes from 'prop-types';

import { MenuItem } from '@material-ui/core';

const overlayLines = (depth, extraPaddingLeft, open, lasts) => {
  const verticalLineFull = i => ({
    pointerEvents: 'none',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 15 + 16 * i,
    width: 1,
    backgroundColor: 'black',
  });

  const verticalLineHalfTop = i => ({
    ...verticalLineFull(i),
    bottom: '50%',
  });

  const verticalLineHalfBottom = i => ({
    ...verticalLineFull(i),
    top: '64%',
  });

  const horizontalLineFull = i => ({
    pointerEvents: 'none',
    position: 'absolute',
    top: '50%',
    height: 1,
    left: 15 + 16 * i,
    width: 34,
    backgroundColor: 'black',
  });

  const horizontalLineHalfLeft = i => ({
    ...horizontalLineFull(i),
    width: 8,
  });

  const horizontalLineHalfLeftBottom = i => ({
    ...horizontalLineHalfLeft(i),
    top: undefined,
    bottom: 0,
  });

  const horizontalLineHalfRight = i => ({
    ...horizontalLineHalfLeft(i),
    left: 41 + 16 * i,
  });

  const lines = [];

  [...Array(depth).keys()].forEach(i => {
    const j = i + 1;

    if (j === depth && lasts[lasts.length - 1]) {
      if (extraPaddingLeft || (!extraPaddingLeft && !open)) {
        lines.push(<div key={j} style={verticalLineHalfTop(j)} />);
        for (let k = lasts.length - 1; k >= 1; k--) {
          if (!lasts[k]) {
            break;
          }
          if (k !== lasts.length - 1) {
            lines.push(<div key={`bt-hr-${k}`} style={horizontalLineHalfLeftBottom(k)} />);
          }
        }
      } else if (open) {
        lines.push(<div key={j} style={verticalLineFull(j)} />);
      }
    } else {
      lines.push(<div key={j} style={verticalLineFull(j)} />);
    }
  });

  if (extraPaddingLeft) {
    lines.push(<div key="last-extra-padding" style={horizontalLineFull(depth)} />);
  } else {
    if (open) {
      lines.push(<div key="last-open" style={verticalLineHalfBottom(depth + 1)} />);
    }
    if (depth !== 0) {
      lines.push(<div key="last-non0-depth-l" style={horizontalLineHalfLeft(depth)} />);
    }
    lines.push(<div key="last-non0-depth-r" style={horizontalLineHalfRight(depth)} />);
  }

  return lines;
};

const Row = props => {
  const {
    depth,
    children,
    extraPaddingLeft,
    displayLines,
    open,
    lasts,
    ...rest
  } = props;
  const style = {
    paddingLeft: 8 * (1 + 2 * depth) + 48 * extraPaddingLeft,
    whiteSpace: 'normal',
    position: 'relative',
  };

  return (
    <React.Fragment>
      <MenuItem style={style} {...rest}>
        {children}
        {displayLines && overlayLines(depth, extraPaddingLeft, open, lasts)}
      </MenuItem>
    </React.Fragment>
  );
};

Row.propTypes = {
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  extraPaddingLeft: PropTypes.bool,
  displayLines: PropTypes.bool,
  open: PropTypes.bool,
  lasts: PropTypes.array,
};

Row.defaultProps = {
  extraPaddingLeft: false,
  displayLines: false,
  open: false,
  lasts: [],
};

export default Row;
