import Text from 'utils/text';
import Errors from 'utils/error';

import types from 'modules/action_types';
const { LocalTypes } = types;

const handleError = (next, { type, error, locale, rest }) => {
  const { type: errorType, payload } = Errors.parse(error.message);

  // local error
  next({ ...rest, payload: errorType, type });

  // global error
  let message = Text.errors[locale][errorType] || 'unknown';
  message = Text.replace(message, payload || {});
  next({ type: LocalTypes.ON_ERROR, payload: message });
};

export default () => ({ dispatch, getState }) => next => action => {
  if (typeof action === 'function') {
    return action(dispatch, getState);
  }

  const { promise, type, ...rest } = action;
  if (!promise) {
    return next(action);
  }

  next({ ...rest, type });

  const actionPromise = promise({}, dispatch)
    .then(
      payload => next({ ...rest, payload, type: `${type}_success` }),
      error => {
        const { locale } = getState().local;
        handleError(next, { type: `${type}_fail`, error, locale, rest })
      }
    )
    .catch(error => {
      console.log('Middleware Error: ', error);
      const { locale } = getState().local;
      handleError(next, { type: `${type}_fail`, error, locale, rest });
    });

  return actionPromise;
};
