import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';

import initRedux from './redux/init';

import AppBase from 'layouts/AppBase';

const history = createBrowserHistory();

const theme = createMuiTheme({
  palette: {
    primary: { // tutor color
      light: '#d1e8f9',
      main: '#36b8e6',
      dark: '#03a8e6',
      contrastText: '#fff',
    },
    secondary: { // student color
      light: '#ff7d7d',
      main: '#ed8078',
      dark: '#d97575',
      contrastText: '#fff',
    },
  },
});

console.log(process.env.REACT_APP_ENV_CONTEXT);

const App = () => (
  <ReduxProvider store={initRedux({}, history)}>
    <ThemeProvider theme={theme}>
      <ConnectedRouter history={history}>
        <AppBase />
      </ConnectedRouter>
    </ThemeProvider>
  </ReduxProvider>
);

export default App;
