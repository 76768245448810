import crypto from 'crypto';

const { ENCRYPTION_KEY, ENCRYPTION_IV } = process.env;

const alg = 'aes-256-cbc';
let key = ENCRYPTION_KEY || process.env.REACT_APP_ENCRYPTION_KEY;
let iv = ENCRYPTION_IV || process.env.REACT_APP_ENCRYPTION_IV;

export const encrypt = text => {
  const cipher = crypto.createCipheriv(alg, Buffer.from(key, 'hex'), Buffer.from(iv, 'hex'));
  let encrypted = cipher.update(text);
  encrypted = Buffer.concat([encrypted, cipher.final()]);
  return encrypted.toString('hex');
};

export const decrypt = text => {
  if (!key || !iv) {
    throw new Error('provide an encryption key and an iv');
  }

  const encryptedText = Buffer.from(text, 'hex');
  const decipher = crypto.createDecipheriv(alg, Buffer.from(key, 'hex'), Buffer.from(iv, 'hex'));
  let decrypted = decipher.update(encryptedText);
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  return decrypted.toString();
};
