import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import dashboardReducer from 'modules/dashboard/reducer';
import localReducer from 'modules/local/reducer';
import requestReducers from 'modules/request_reducers';

const adjustedRequestReducers = {};
Object.keys(requestReducers).forEach(key => {
  adjustedRequestReducers[key] = combineReducers(requestReducers[key]);
});

export default history => combineReducers({
  history: (state = history) => state,
  router: connectRouter(history),
  dashboard: dashboardReducer,
  local: localReducer,
  request: combineReducers(adjustedRequestReducers),
});
