import { get, mapValues, trim } from 'lodash';
import texts from './texts.json';
import env from 'env';

class Text {
  availableLocales = {
    en: { name: 'English', flag: '🇺🇸' },
    fr: { name: 'Français', flag: '🇫🇷' },
  };

  general = env.locales.reduce((acc, l) => ({
    ...acc,
    [l]: this.loadTexts('general', l),
  }), {});

  errors = env.locales.reduce((acc, l) => ({
    ...acc,
    [l]: this.loadTexts('errors', l)
  }), {});

  loadTexts(path, locale = 'en') {
    if (!path || typeof path !== 'string') {
      return {};
    }

    const obj = get(texts, path);

    if (!obj) {
      return {};
    }

    if (typeof Object.values(obj)[0] === 'string') {
      return obj[locale];
    }

    return this.replaceWithLocale(obj, locale);
  }

  replaceWithLocale(obj, locale) {
    return mapValues(obj, v => {
      if (typeof Object.values(v)[0] === 'string') {
        return v[locale];
      }
      return this.replaceWithLocale(v, locale);
    });
  }

  replaceWithString(obj) {
    return mapValues(obj, v => {
      if (typeof Object.values(v)[0] === 'string') {
        return '';
      }
      return this.replaceWithString(v);
    });
  }

  replace(text, context) {
    return text.replace(/\[.*?\]/g, x => context[trim(x, '[]')]);
  }

  getTextShape(path) {
    if (!path || typeof path !== 'string') {
      return {};
    }

    const obj = get(texts, path) || {};

    if (typeof Object.values(obj)[0] === 'string') {
      return '';
    }

    return this.replaceWithString(obj);
  };
}

export default new Text();
